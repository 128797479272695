import React from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Marquee = () => {
 
  return (
    <>
      <section id="Marquee">
        <div className="el">chevrolet</div>
        <div className="marquee-w">
          <div className="marquee">
            <span>
              THE CEO<div className="commnity"> SHOW - </div> THE CEO
              <div className="commnity"> SHOW - </div>
            </span>
          </div>
          <div className="marquee marquee2">
            <span>
              THE CEO<div className="commnity"> SHOW - </div> THE CEO
              <div className="commnity"> SHOW - </div>
            </span>
          </div>
        </div>
      </section>
    </>
  );
};

export default Marquee;
